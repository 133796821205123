// config.js

const bizIcon = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/Riio4KsXUEKMMxd4cgwa/media/670c5aa88a1004105d9509ff.png";
const imageOne = "https://st2.depositphotos.com/20363444/46170/i/450/depositphotos_461704628-stock-photo-cleaner-overalls-using-vacuum-cleaner.jpg";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/Riio4KsXUEKMMxd4cgwa/media/670c6c2a2af3600eb79233fd.jpeg";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/Riio4KsXUEKMMxd4cgwa/media/670c6c458a100401c9964a56.jpeg";
const imageFour = "https://images.pexels.com/photos/3768910/pexels-photo-3768910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/Riio4KsXUEKMMxd4cgwa/media/670c6be8614019356de39df0.jpeg";

const socialMediaLink = "https://sacleaningllc.com/";

export const siteConfig = {
  title: "S/A CLEANING LLC",
  metaDescription: "S/A CLEANING LLC",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-yellow-500"
  },
  logo: {
    src: bizIcon,
    alt: "S/A CLEANING LLC",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "S/A Cleaning LLC",
    headline: "Making Homes Shine, One Clean at a Time",
    description: "With years of experience in residential and commercial cleaning, S/A Cleaning LLC offers reliable, professional services to keep your spaces spotless and welcoming. Specializing in deep cleaning, routine maintenance, and move-in/move-out services, we ensure each space shines to meet your standards.",
  },
  images: {
    business: {
      src: imageOne,
      alt: "Making Homes Shine, One Clean at a Time",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      name: 'Expert Cleaners',
      description: 'Our team of professional cleaners is trained to tackle any mess, ensuring your home or office is spotless after every visit.',
    },
    {
      name: 'Customized Service',
      description: 'We tailor our cleaning services to meet your specific needs and preferences, taking care of every nook and cranny to your satisfaction.',
    },
    {
      name: 'Eco-Friendly Products',
      description: 'We use environmentally safe cleaning products that are tough on dirt but gentle on your home, office, and the planet.',
    },
  ],
  services: {
    sectionTitle: "Cleaning Services",
    description: "Our team provides flexible and reliable cleaning options tailored to fit your lifestyle. Whether you're home or away, we ensure your space is cleaned thoroughly and respectfully. Many clients grant us secure access, allowing us to clean while they are at work or attending to errands. If you prefer to be present, that’s perfectly fine too!",
    callouts: [
      {
        name: 'Regular Residential Cleaning',
        description: 'Weekly, bi-weekly, or monthly cleaning services to keep your home consistently spotless.',
        details: 'Our regular cleaning service covers dusting, vacuuming, mopping, and general tidying to maintain a fresh and inviting home environment.',
        imageSrc: imageTwo,
        imageAlt: 'Clean and organized living room in a residential home',
      },
      {
        name: 'Deep Cleaning & Specialty Services',
        description: 'Intensive cleaning options for areas needing extra care, as well as move-in/move-out and specialty services.',
        details: 'Ideal for seasonal refreshes or preparing for special occasions, our deep cleaning service focuses on high-traffic and overlooked areas. Specialty services include carpet cleaning, window washing, and more.',
        imageSrc: imageThree,
        imageAlt: 'Cleaner working on a thorough deep cleaning for a kitchen',
      },
      {
        name: 'Commercial Cleaning',
        description: 'Professional cleaning solutions tailored for office spaces and commercial properties.',
        details: 'Our commercial cleaning team ensures a hygienic and well-maintained environment for employees and clients alike, from workstations to common areas.',
        imageSrc: imageFour,
        imageAlt: 'Professional cleaner attending to an office environment',
      }
    ],
    otherServices: [
      "Post-Construction Cleaning",
      "Event Clean-Up",
      "Specialty Disinfection Services"
    ]
  },
  about: {
    sectionTitle: "About Our Team",
    description: "At S/A Cleaning LLC, we believe a clean space is essential for well-being and comfort. Founded in 2020, our team has proudly delivered top-notch cleaning services, transforming homes and offices into spotless sanctuaries. We are committed to providing exceptional, personalized cleaning solutions to meet each client's unique needs, ensuring every space we service shines with cleanliness and care.",
    image: {
      src: imageFive,
      alt: "S/A Cleaning LLC team providing residential cleaning services",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Goose Creek, SC, USA",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3367.8386942015676!2d-80.0320874842442!3d32.98100847987938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fe6292bbcd81f3%3A0x42f90de8de13f8e7!2sGoose%20Creek%2C%20SC%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { name: 'Apple Maps', url: 'https://maps.apple.com/?q=Goose%20Creek,%20SC' },
      { name: 'Bing Maps', url: 'https://www.bing.com/maps?q=Goose+Creek,+SC' },
      { name: 'Google Earth', url: 'https://earth.google.com/web/search/Goose+Creek,+SC,+USA/' },
      { name: 'Google Maps', url: 'https://goo.gl/maps/x9Bn7aThbbH2' }
    ]
  },
  contact: {
    sectionTitle: "Ready to Schedule Your Cleaning Service?",
    description: "Contact our management team to discuss your cleaning needs or to schedule a service. Whether it’s a one-time deep clean, routine maintenance, or a specialty service, we’re here to provide top-quality service every step of the way.",
    manager: {
      name: "Mauricio Barbosa",
      title: "Contact Management",
      email: "amauriciobarbosa@hotmail.com",
    },
    socialLinks: {
      facebook: socialMediaLink,     
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      linkedin: socialMediaLink,
      youtube: socialMediaLink
    },
    contactNumbers: [
      "Mobile: (843) 926-0520",
    ],
    logo: bizIcon,
    emailRecipient: "amauriciobarbosa@hotmail.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
